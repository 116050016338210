<template>
  <div class="container-fluid">
    <!-- Top Navigation -->
    <div style="position: absolute;top: 25px" class="bg_maroon ">
      <div class="mt--5 d-inline-block pointer" @click="$router.go(-1)">
          <img class="d-inline-block mt--1" src="/img/back_arrow.svg" alt="">
          <h3 class="text-white mb-0 ml-2 d-inline-block">Back</h3>
      </div>
    </div>
    <!-- Top Navigation Finished-->
    <div class="d-flex justify-content-between align-items-center pt-4">
      <h1 class="font-weight-600 m-0">Message Template</h1>
      <base-button class="btn-secondary-action title-btn-padding button-font" @click="createMessageTemplate('formValidator')">Add New</base-button>
    </div>
    <div class="mt-4" v-loading="loaders.table">
      <message-template-table :tableData="messageTemplates"
                        :tableColumns="tableData"
                        @editItem="editMessageTemplate"
                        @deleteItem="deleteMessageTemplate">
      </message-template-table>
    </div>

    <!-- Delete Modal -->
    <modal v-loading="loaders.modal" :show.sync="modals.delete">
     <template slot="header">
        <h5 class="modal-title">Are you sure?</h5>
     </template>
     <div class="content mt--4">
      You want to remove this template.
     </div>
     <template slot="footer">
         <base-button type="secondary" class="btn-gray" @click="modals.delete = false">Cancel</base-button>
         <base-button type="danger" @click="deleteMessage">Remove</base-button>
     </template>
   </modal>

   <!-- Delete Modal Finished -->

   <!-- Edit Modal -->

   <modal v-loading="loaders.modal" :show.sync="modals.messageTemplate">
        <h6 slot="header" class="modal-title" id="modal-title-default">{{currentAction}} Message Template</h6>

        <validation-observer v-slot="{handleSubmit}" ref="messageTemplateForm">
          <form class="needs-validation"
                @submit.prevent="handleSubmit(submitForm)">
            <base-input :rules="{required: true}"
                        name="message title"
                        label="Title" v-model="messageTemplate.form.title"
                        placeholder="Welcome Message">
            </base-input>

            <div class="mt-4">
              <base-input label="Message" :rules="{required: true}" name="message">
                  <textarea v-model="messageTemplate.form.description"
                          class="form-control"
                          rows="4">
                  </textarea>
              </base-input>
            </div>

            <div class="d-flex justify-content-between mt-4">
              <label class="form-control-label">Show Status</label>
              <div class="d-flex mt-1">
                <span class="inactive-text pr-3">Inactive</span>
                <base-switch ref="templateSwitcher" class="status-toggle"
                              v-model="messageTemplate.form.switch">
                </base-switch>
                <span class="active-text pl-3">Active</span>
              </div>
            </div>

            <div class="float-right mt-4">
              <base-button type="secondary" class="ml-auto btn-gray" @click="modals.messageTemplate = false">
                Cancel
              </base-button>
              <base-button native-type="submit" type="submit" class="btn-primary-action">{{getModalButtonText}}
              </base-button>
            </div>

        </form>
        </validation-observer>
    </modal>

   <!-- Edit Modal Finished -->

    <modal :show.sync="modals.deleteRestrictionModal.visible">
      <template slot="header">
        <h5 class="modal-title">Checklist Template Alert!</h5>
      </template>
      <div  class="mt--4 ml--1">
        <span>To Delete Template(s) please contact your account representative.</span>
      </div>
      <template slot="footer">
        <base-button type="secondary" class="btn-gray" @click="modals.deleteRestrictionModal.visible = false">Close</base-button>
      </template>
    </modal>

  </div>
</template>

<script>
import MessageTemplateTable from "@/components/Tables/NonPaginatedTables/ActionsTable.vue";
import _ from 'lodash';
export default {
  name: "MessageTemplate",
  components: {
    MessageTemplateTable,
  },
  data() {
    return {
      loaders: {
        modal: false,
        table: false,
      },
      modals: {
        delete: false,
        messageTemplate: false,
        deleteRestrictionModal : {
          visible:false
        }
      },
      currentAction: '',
      messageTemplate: {
        form: {
          id: '',
          title: '',
          description: '',
          status: true,
          switch: true,
        },
        delete: {
          id: '',
        },
      },
      messageTemplates: [],
      tableData: [
        {
          prop: "title",
          label: "Message Title",
          minWidth: 160,
        },
        {
          prop: "description",
          label: "Message",
          minWidth: 250,
        },
        {
          prop: "created",
          label: "Created Date",
          minWidth: 180,
        },
        {
          prop: "status",
          label: "Status",
          minWidth: 100,
        }
      ]
    }
  },
  computed: {
    getModalButtonText() {
      return this.currentAction === 'Edit' ? 'Update' : 'Add'
    }
  },
  methods: {

    customizeSwitch() {
      let switcher = this.$refs.templateSwitcher.$el.children[1];
      switcher.setAttribute('data-label-off', '')
      switcher.setAttribute('data-label-on', '')
    },

    clearFormFields() {
      this.messageTemplate.form = {
        id: '',
        title: '',
        description: '',
        status: true,
        switch:true,
      }
    },

    createMessageTemplate(id) {
      this.clearFormFields();
      this.$refs.messageTemplateForm.reset();
      this.currentAction = 'Add';
      this.modals.messageTemplate = true;
    },

    editMessageTemplate(form) {
      this.clearFormFields();
      this.$refs.messageTemplateForm.reset();
      this.currentAction = 'Edit';
      this.modals.messageTemplate = true;
      this.messageTemplate.form = _.cloneDeep(form);
      this.setSwitch();
    },

    setSwitch() {
      if(this.messageTemplate.form.status==0) {
        this.messageTemplate.form.switch = false
      }
      else if(this.messageTemplate.form.status==1) {
        this.messageTemplate.form.switch = true
      }
    },

    setStatus() {
      if(this.messageTemplate.form.switch==false) {
        this.messageTemplate.form.status = 0
      }
      else if(this.messageTemplate.form.switch==true) {
        this.messageTemplate.form.status = 1
      }
    },

    deleteMessageTemplate(id) {
      if(this.$store.getters.getAuthUser.type === 'sub_user') {
        this.modals.delete = true;
        this.messageTemplate.delete.id = id;
      }
      else {
        this.modals.deleteRestrictionModal.visible = true;
      }
    },

    getMessageTemplates() {
      let vm=this
      vm.loaders.table = true;
      this.$store.dispatch('_getMessageTemplates').then(response => {
        this.messageTemplates = response;
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Message Templates',
          message: message
        });
      }).finally(() => {
        vm.loaders.table = false;
      });
    },

    submitForm() {
      if(this.currentAction === 'Add') {
        this.addMessageTemplate();
      }
      else {
        this.updateMessageTemplate();
      }
    },

    addMessageTemplate() {
      let vm=this;
      vm.loaders.modal = true;
      vm.setStatus();
      this.$store.dispatch('_addMessageTemplate', _.omit(this.messageTemplate.form, 'id')).then(response => {
        vm.clearFormFields();
        vm.getMessageTemplates();
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Message Templates',
          message: message
        });
      }).finally(() => {
        vm.modals.messageTemplate = false;
        vm.loaders.modal = false;
      });
    },

    updateMessageTemplate() {
      let vm=this;
      vm.loaders.modal = true;
      vm.setStatus();
      this.$store.dispatch('_editMessageTemplates', this.messageTemplate.form).then(response => {
        vm.clearFormFields();
        vm.getMessageTemplates();
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Message Templates',
          message: message
        });
      }).finally(() => {
        vm.modals.messageTemplate = false;
        vm.loaders.modal = false;
      });
    },

    deleteMessage() {
      let vm=this;
      vm.loaders.modal = true;
      this.$store.dispatch('_deleteMessageTemplates', vm.messageTemplate.delete).then(response => {
        vm.getMessageTemplates()
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Message Templates',
          message: message
        });
      }).finally(() => {
        vm.modals.delete = false;
        vm.loaders.modal = false;
      });
    },


  },
  mounted() {
    this.getMessageTemplates();
    this.customizeSwitch();
  }
};
</script>

<style>
  @import './alerts.scss';
</style>
