<template>
  <div class="content mt-3">
    <!-- Card -->
    <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
      <!-- Table -->
      <el-table
        :data="tableData"
        row-key="id"
        header-row-class-name="thead-light"
        :cell-class-name="statusClass"
      >
        <template v-for="column in tableColumns">
          <el-table-column
            :key="column.label"
            v-bind="column"
          >
            <template v-if="column.prop === 'status'" scope="scope">
              {{ getStatus(scope) }}
            </template>

            <template v-else-if="column.prop === 'created'" scope="scope">
              {{ formatDate(scope.row.date) }}
            </template>

          </el-table-column>
        </template>
        <el-table-column min-width="100px" align="right" label="Actions">
          <div slot-scope="{ $index, row }" class="d-flex">
            <img class="pointer" @click="handleEdit($index, row)" src="/img/icons/buttons/edit-rectangle.svg" alt="">
            <img class="pointer icon" @click="handleDelete($index, row)" src="/img/icons/buttons/trash-rectangle.svg" alt="">
          </div>
        </el-table-column>
      </el-table>
    </card>

  </div>
</template>
<script>
import { Table, TableColumn } from "element-ui";

export default {
  props: ['tableData', 'tableColumns'],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,

  },
  data() {
    return {

    }
  },
  methods: {
    handleEdit(index, row) {
      this.$emit('editItem', row);
    },

    handleDelete(index, row) {
      this.$emit('deleteItem', row.id);
    },

    getStatus(scope) {
      if(scope.row.status==1) {
        return 'Active';
      }
      else return 'Inactive'
    },

    statusClass({row, column}) {
      if(column.label==='Status') {
        if (row.status == 0) {
          return 'inactive-status-color';
        } else if (row.status == 1) {
          return 'active-status-color';
        }
      }

      return '';
    }
  },
};
</script>
<style scoped>
.no-border-card .card-footer {
  border-top: 0;
}
.pointer {
  cursor:pointer;
}

.icon {
  margin-left: 3px;
  width: 27px!important;
  height: 27px!important;
}

.modal-subtitle {
  display: block!important;
}
</style>

